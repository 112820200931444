import {
  InlineMessage,
  PortfolioMapbox,
  InputType,
  AnyInputConfig,
  GridForm,
  useTracking,
} from '@flock/shared-ui'
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useState } from 'react'
import millify from 'millify'

import theme from '@flock/shared-ui/src/theme/theme'
import { Close } from '@mui/icons-material'
import {
  Core_TransactionType,
  LandingCreateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { createLeadHelper, localStore, UserEventType } from '@flock/utils'
import { useMutation } from '@apollo/client'
import { MAPBOX_TOKEN } from '../../constants'
import SectionLayout from '../SharedComponents/SectionLayout'
import { PropertyAsset } from '../types'
import { getEventTokenUUID } from '../../utils/analytics'

type PortfolioMapSectionProps = {
  propertyAssets: PropertyAsset[]
  dailyMetrics: any
}

type StatCardProps = {
  label: string
  value: string
}

const StatCard = (props: StatCardProps) => {
  const { label, value } = props
  return (
    <Box
      key={label}
      display="flex"
      justifyContent="center"
      padding="8px 16px"
      gap={{ xs: '24px', sm: '36px' }}
      borderRadius="24px"
      sx={{
        boxShadow: `0px 8px 24px rgba(0, 0, 0, 0.08)`,

        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h2" whiteSpace="nowrap" color="moneyGreen.main">
          {value}
        </Typography>
        <Typography variant="p2" whiteSpace="nowrap" color="gray8.main">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

const contactInfoInputConfigs: AnyInputConfig[] = [
  {
    name: 'fullName',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Full name',
      placeholder: 'First and last name',
    },
  },
  {
    name: 'email',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Email',
      format: 'email',
      placeholder: 'email@flockhomes.com',
    },
  },
  {
    name: 'phone',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Phone',
      format: 'phone',
      placeholder: '111-111-1111',
    },
  },
]

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '500px',
  width: '70%',
  maxHeight: '75vh',
  height: '370px',
  overflowY: 'auto',
  padding: '48px',
  borderRadius: '24px',
})

const PortfolioMapSection = (props: PortfolioMapSectionProps) => {
  const { propertyAssets, dailyMetrics } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [createLead] = useMutation(LandingCreateLeadDocument)
  const { track } = useTracking()
  const onClose = () => {
    setIsOpen(false)
  }

  const processedAssets = propertyAssets.map((asset: any) => ({
    ...asset,
    ...asset.address,
    baths: asset.baths + (asset.halfBaths / 2 || 0),
  }))

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const submitContactInfo = async (result: any) => {
    try {
      setLoading(true)

      const leadData = {
        contactInfo: {
          fullName: result.fullName,
          email: result.email,
          phoneNumber: result.phone,
        },
        answers: JSON.stringify({
          isPortfolioInfoContact: true,
        }),
        transactionType: Core_TransactionType.TransactionTypeContactInformation,
        eventTokenUuid: getEventTokenUUID(),
      }

      const queryParams = localStore?.getItem('queryParams')
      let parsedQueryParams: any = {}
      if (queryParams) {
        parsedQueryParams = JSON.parse(queryParams)
      }

      track('requestedPortfolioContactInfo', {
        type: 'button',
        leadConversion: 'finished',
        portfolioInfoCta: true,
        actionType: UserEventType.BUTTON_CLICK,
      })
      await createLeadHelper(leadData, parsedQueryParams, createLead)

      setLoading(false)
      setIsOpen(false)
    } catch (e) {
      setLoading(false)
      setIsOpen(false)
    }
  }

  // defaults to portfolio, which will always be the last item in the array
  const dailyMetric = dailyMetrics[dailyMetrics.length - 1]

  const totalValuationNum = parseFloat(dailyMetric.totalValuation) / 100
  const portfolioValuationString = `$${millify(totalValuationNum!, {
    precision: 0,
    lowercase: true,
    units: ['', 'k', 'm', 'b', 't'],
  })}`

  const portfolioHomeCountString = dailyMetric.numProperties.toString()

  return (
    <SectionLayout
      name="portfolio-map"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      <Modal open={isOpen} onClose={onClose}>
        <ModalWrapper>
          <Box position="fixed" top="16px" right="16px">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography variant="h3" color="moneyGreen.main">
              Request Portfolio Information
            </Typography>
            <GridForm
              onSubmit={submitContactInfo}
              inputConfigs={contactInfoInputConfigs}
              loading={loading}
              ctaBoxProps={{
                width: '114px',
                position: 'fixed',
                bottom: '24px',
                right: '24px',
              }}
              gridProps={{
                spacing: 3,
              }}
              ctaText="Submit"
            />
          </Box>
        </ModalWrapper>
      </Modal>
      <Grid item xs={12}>
        <Box display="flex" gap="24px">
          <Grid item xs={6}>
            <StatCard value={portfolioHomeCountString} label="total homes" />
          </Grid>
          <Grid item xs={6}>
            <StatCard
              value={portfolioValuationString}
              label="total portfolio value"
            />
          </Grid>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ height: isMobile ? '256px' : '496px' }}
      >
        <PortfolioMapbox
          propertyAssets={processedAssets}
          mapboxToken={MAPBOX_TOKEN || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <InlineMessage
          text="Want to know more about Flock’s Portfolio?"
          variant="button"
          label="Request information"
          size={isDesktop ? 'large' : 'small'}
          trackingConfig={{
            properties: {
              leadConversion: 'started',
              portfolioInfoCta: true,
            },
          }}
          onClick={() => setIsOpen(true)}
        />
      </Grid>
    </SectionLayout>
  )
}

export default PortfolioMapSection
